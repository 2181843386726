<template>
  <div>
    <v-card class="card">
      <div class="card__img">
        <img @click="showDetails" :src="publication.poster" />
      </div>
      <div class="card__content">
        <v-card-title @click="showDetails" class="card__title">
          <span class="card__subTitle">{{ getType(publication.type) }}</span>
          <br />
          {{ publication.name }}
        </v-card-title>

        <v-card-text class="card__des">
          <p>
            {{ textLengthFilter(publication.brief_description, 120) }}
          </p>
        </v-card-text>
        <v-card-actions class="card__btns">
          <FavoriteBtn :item="publication" @onFavorite="onFavorite" />
        </v-card-actions>
      </div>
    </v-card>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import FavoriteBtn from "~/components/course/FavoriteBtn.vue";
import { useAuthStore } from "~/stores/AuthStore";

// Props
const props = defineProps({
  publication: {
    type: Object,
    default: () => ({}),
  },
});

// Composables
const { textLengthFilter } = useFilters(); // Destructure the filter function
const authStore = useAuthStore();
const router = useRouter();

// Refs and reactive properties
const clicked = ref(false);

// Computed properties
const isLogin = computed(() => authStore.auth.isLoggedIn);

// Methods
const showDetails = () => {
  router.push({
    path: `publications/${props.publication.id}`,
    query: { type: props.publication.type },
  });
};

const onFavorite = () => {
  emit("onFavorite", { value: true });
};

const getType = (type) => {
  switch (type) {
    case "books":
      return "كتاب";
    case "cards":
      return "بطاقة";
    case "albums":
      return "إلبوم";
    default:
      return "";
  }
};
</script>
<style lang="scss" scoped>
.card {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  // width: 499px;
  height: 322px;
  border: none !important;
  box-shadow: none !important;
  * {
    word-break: break-word !important;
  }
  .card__img {
    cursor: pointer;
    width: 45% !important;
    img {
      width: 100%;
      height: 100%;
      box-shadow: 6px 3px 16px 0 rgba(0, 0, 0, 0.16);
      border-radius: 5px;
    }
  }
  .card__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 12px;
    width: 55%;
    .card__title {
      cursor: pointer;
      padding: 0px 16px;
      display: block;
      font-size: 28px;
      font-weight: bold;
      line-height: 1.5;
    }
    .card__subTitle {
      display: inline-block;
      font-size: 13px;
      font-weight: bold;
      color: #989898;
      padding: 10px 5px;
    }
    .price {
      position: relative;
      margin-right: 30px !important;
      &:before {
        content: "";
        width: 7px;
        height: 8px;
        margin: 0px;
        background-color: #bcbcbc;
        border-radius: 50%;
        position: absolute;
        right: -15px;
        top: 5px;
      }
    }
    .card__btns {
      padding-right: 0 20px;
    }
    .card__des {
      font-size: 15px;
      font-weight: normal;
      line-height: 1.6;
      text-align: right;
      color: #000;
      min-height: 100px;
    }
  }
}
@media (max-width: 991px) {
  .card {
    .card__content {
      .card__title {
        font-size: 22px;
      }
      .card__subTitle {
        font-size: 13px;
      }
      .price {
        margin-right: 20px !important;
      }
      .card__des {
        font-size: 12px;
      }
      .card__btns {
        padding: 0 10px;
      }
    }
  }
}
@media (max-width: 600px) {
  .card {
    height: 275px;
    .card__content {
      .card__title {
        font-size: 15px;
      }
      .card__subTitle {
        font-size: 12px;
      }
      .price {
        margin-right: 20px !important;
      }
      .card__des {
        font-size: 10px;
      }
      .card__btns {
        padding: 0 10px;
      }
    }
  }
}
</style>
